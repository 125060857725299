import React from 'react'
import {Link, Redirect} from 'react-router-dom'
import styled from 'styled-components'
import axios from 'axios'
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

import {
  Container, FormContainer, TitleContainer, FormTitle, Input, Button, BLogo, ElementContainer, LinkContainer, CustomLink,
  Select, Option, ConfirmationText, RegisterFormContainer
} from './styles';

import {
  SpanLabel,
} from '../../commons';
import {
  adminRegister,
} from '../../store/actions'; 
const prodKeys = require('../../config/prodKeys');
const url = prodKeys.apiUrl;
const util = require('util');
const {
  nameValidate,
  passwordValidate,
  emailValidate,
  mismatchPassword,
  phoneValidate,
} = require('../../services/validate');

const bodyHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

class AdminSignup extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      user: {
        email: '',
        password: '',
        confirmPassword: '',
        firstname: '',
        lastname: '',
        phone: '',
        role: 'admin',
        status: 'active',
      },
      invalidMsg: '',
    };
  }

addUser = () => {
    let newUser = this.state.user;
    console.log('user object: ', util.inspect(newUser));
    console.log('url : ', url);
    this.props.onAdminRegister(newUser);
};

signUpHandler = (event) => {
    console.log('submitting form...');
    event.preventDefault();
    if (this.state.invalidMsg == '') {
      this.addUser();
      this.createNotification('','Đăng ký tài khoản thành công!');
    } else {
      this.setState(prevState => ({
        ...prevState,
        invalidMsg: 'Please correct info: ' + this.state.invalidMsg,
      }))
    }
}



  createNotification = (title,message) => {
    toast.success(message, {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  render() {
    return (
      <Container>
        {/* {this.renderRedirect()} */}
        <RegisterFormContainer>
          <FormTitle>Account Registration</FormTitle>
          {
            (this.state.invalidMsg != '') ? 
            <SpanLabel 
              text={this.state.invalidMsg}
              style={{color: 'red'}}
            /> : null
          }
          
          <Input placeholder="Email" value={this.state.user.email} onChange={(e) => {
            let {user} = this.state;
            user.email = e.target.value;
            this.setState({user});
            let _validateResult = emailValidate(this.props.language, e.target.value);
            this.setState(prevState => ({
              ...prevState,
              invalidMsg: _validateResult,
            }))
          }} />
          <Input placeholder="Password" type="password" value={this.state.user.password} onChange={(e) => {
            let {user} = this.state;
            user.password = e.target.value;
            this.setState({user});
            let _validateResult = passwordValidate(this.props.language, e.target.value);
            this.setState(prevState => ({
              ...prevState,
              invalidMsg: _validateResult,
            }))
          }}  />
          <Input placeholder="Confirm Password" type="password" 
            value={this.state.user.confirmPassword} 
            onChange={(e) => {
              let {user} = this.state;
              user.confirmPassword = e.target.value;
              this.setState({user});
              let _validateResult = mismatchPassword(this.props.language, this.state.user.password, e.target.value);
              this.setState(prevState => ({
                ...prevState,
                invalidMsg: _validateResult,
              }))
            }}  
          />

          <Input placeholder="First Name" value={this.state.user.firstname} onChange={(e) => {
            let {user} = this.state;
            user.firstname = e.target.value;
            this.setState({user});
            let _validateResult = nameValidate(this.props.language, e.target.value);
            this.setState(prevState => ({
              ...prevState,
              invalidMsg: _validateResult,
            }))
          }} />
          <Input placeholder="Lastname" value={this.state.user.lastname} onChange={(e) => {
            let {user} = this.state;
            user.lastname = e.target.value;
            this.setState({user});
            let _validateResult = nameValidate(this.props.language, e.target.value);
            this.setState(prevState => ({
              ...prevState,
              invalidMsg: _validateResult,
            }))
          }} />
          <Input placeholder="Phone" value={this.state.user.phone} onChange={(e) => {
            let {user} = this.state;
            user.phone = e.target.value;
            this.setState({user});
            let _validateResult = phoneValidate(this.props.language, e.target.value);
            console.log('validate phone result: ', _validateResult);
            this.setState(prevState => ({
              ...prevState,
              invalidMsg: _validateResult,
            }))
          }} />

          <Select value={this.state.user.role} onChange={(e) => {
            let {user} = this.state;
            user.role = e.target.value;
            this.setState({user});
          }}>
            <Option value="admin">Admin</Option>
            <Option value="staff">Staff</Option>
          </Select>

          <ConfirmationText>
            By lick to registrate your account, you agree with our <CustomLink to='/'>terms and services</CustomLink> Australia Grain Supply Chain
          </ConfirmationText>
          <Button 
            onClick={this.signUpHandler}
          >Register</Button>
          <LinkContainer>
            Already registered: <CustomLink to='/'>Login</CustomLink>
          </LinkContainer>
        </RegisterFormContainer>
        <ToastContainer />
      </Container>
    );
  }
}

const mapStateToProps = state => {
 
  return {
      user: state.authState.currentUser,
      language: state.topMenu.language || "en",
  }
}

const mapDispatchToProps = dispatch => {
  return {
      onAdminRegister: (user) => dispatch(adminRegister(user)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminSignup);

/*********************Start Stylesheet**********************/



const ComponentContainer = styled.section`
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.5);
`;

// const FormContainer = styled(ComponentContainer)`
//   padding: 30px 30px 30px 70px;
//   background-color: #ffffff;
  
//   background-repeat: no-repeat;
// `;

// const FormTitle = styled.div`
//   font-size: 28px;
//   text-align: center;
//   margin-bottom: 15px;
// `;

// const Input = styled.input`
//   border: solid 1px #B7B7B7;
//   height: 40px;
//   width: 390px;
//   font-size: 14px;
//   padding-left: 10px;
//   margin-top: 15px;

//   :focus {
//     background-color: #FFF6DB;
//     box-shadow:0 3px 8px 0 rgba(123, 193, 255, 0.7);
//   }
// `;




/*********************End Stylesheet**********************/